@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box !important;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
}

.box {
    position: relative;
    max-width: 600px;
    width: 90%;
    height: 400px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

/* common */
.ribbon {
    width: 9rem;
    height: 5rem;
    overflow: hidden;
    position: absolute;
}
.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid rgb(234 179 8);
}
.ribbon span {
    position: absolute;
    display: block;
    width: 14rem;
    padding: 0.5rem 0;
    background-color: rgb(234 179 8);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: black;
    font:
        700 0.7rem/1 'Lato',
        sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
}

/* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}
.ribbon-top-left::before {
    top: 0;
    right: 0;
}
.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}
.ribbon-top-left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
    top: -1px;
    right: -1px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
}
.ribbon-top-right::before {
    top: 0;
    left: 0;
}
.ribbon-top-right::after {
    bottom: 0;
    right: 0;
}
.ribbon-top-right span {
    left: 0;
    top: 1.2rem;
    transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
    bottom: -10px;
    left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.ribbon-bottom-left::before {
    bottom: 0;
    right: 0;
}
.ribbon-bottom-left::after {
    top: 0;
    left: 0;
}
.ribbon-bottom-left span {
    right: -25px;
    bottom: 30px;
    transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
    bottom: -10px;
    right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
    border-bottom-color: transparent;
    border-right-color: transparent;
}
.ribbon-bottom-right::before {
    bottom: 0;
    left: 0;
}
.ribbon-bottom-right::after {
    top: 0;
    right: 0;
}
.ribbon-bottom-right span {
    left: -25px;
    bottom: 30px;
    transform: rotate(-225deg);
}

/* Animation */
@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        /* opacity: 0; */
    }
    100% {
        transform: translateX(0);
        /* opacity: 1; */
    }
}

.slide-in-left {
    animation: slideInFromLeft 1s ease-out forwards;
}
